var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-card', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Role Name",
            "vid": "name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-group', {
                attrs: {
                  "label": "Role Name"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "placeholder": "Name",
                  "autocomplete": "off"
                },
                model: {
                  value: _vm.form.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "name", $$v);
                  },
                  expression: "form.name"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-row', {
          staticClass: "match-height"
        }, _vm._l(_vm.permissionsList, function (item, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "md": "6",
              "lg": "4"
            }
          }, [_c('b-card', {
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-body', [_c('b-card-title', {
            staticClass: "text-capitalize d-flex align-items-center"
          }, [_c('b-form-checkbox', {
            attrs: {
              "value": index,
              "size": "md"
            },
            on: {
              "change": function change($event) {
                return _vm.onChangeSelect(index);
              }
            },
            model: {
              value: _vm.selectedAll,
              callback: function callback($$v) {
                _vm.selectedAll = $$v;
              },
              expression: "selectedAll"
            }
          }), _vm._v(" " + _vm._s(_vm.indexLabel(index)) + " ")], 1), _c('b-card-text', [_c('b-form-group', _vm._l(item, function (child) {
            return _c('b-form-checkbox', {
              key: child.id,
              staticClass: "mb-1",
              attrs: {
                "value": child.id
              },
              model: {
                value: _vm.selected,
                callback: function callback($$v) {
                  _vm.selected = $$v;
                },
                expression: "selected"
              }
            }, [_c('span', {
              staticClass: "d-block"
            }, [_vm._v(" " + _vm._s(_vm.indexLabel(child.name)) + " "), _c('help-circle-icon', {
              directives: [{
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.right",
                value: _vm.indexLabel(child.description),
                expression: "indexLabel(child.description)",
                modifiers: {
                  "hover": true,
                  "right": true
                }
              }],
              staticClass: "ml-1",
              staticStyle: {
                "cursor": "help"
              },
              attrs: {
                "size": "1.5x"
              }
            })], 1)]);
          }), 1)], 1)], 1)], 1)], 1);
        }), 1), _c('b-card', [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12 text-right"
          }
        }, [_c('LoadingButton'), _c('b-button', {
          attrs: {
            "type": "reset",
            "variant": "outline-secondary"
          }
        }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }